




































































































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'Loading',
  components: {}
})
class App extends Vue { public created() {}
}

export default App;
