import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=7288d77e&scoped=true&"
import script from "./loading.vue?vue&type=script&lang=ts&"
export * from "./loading.vue?vue&type=script&lang=ts&"
import style0 from "./loading.vue?vue&type=style&index=0&id=7288d77e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7288d77e",
  null
  
)

export default component.exports