export default (property: string, order: string, data: any) => {
  const dotIndex = property.indexOf('.');

  let childProp: any;

  if (dotIndex > -1) {
    childProp = property.slice(dotIndex + 1, property.length);
    property = property.slice(0, dotIndex);
  }

  const lesser = -1;
  const higher = 1;

  let prevTag: string | number;
  let nextTag: string | number;

  return data.sort((prev: any, next: any) => {

    const prevProp: string = childProp ? prev[property][childProp] : prev[property];
    const nextProp: string = childProp ? next[property][childProp] : next[property];

    if (isNumber(prevProp)) {
      prevTag = parseInt(prevProp, 10);
    } else {
      if (prevProp.includes(':')) {
        prevTag = parseInt(prevProp.replace(/[^0-9]/g, ''), 10);
      } else {
        prevTag = `${prevProp}`.toLowerCase();
      }

    }
    if (isNumber(prevProp)) {
      nextTag = parseInt(nextProp, 10);
    } else {
      if (nextProp.includes(':')) {
        nextTag = parseInt(nextProp.replace(/[^0-9]/g, ''), 10);
      } else {
        nextTag = `${nextProp}`.toLowerCase();
      }
    }

    // if (property.indexOf('date') > -1) {
    //   lesser = 1;
    //   higher = -1;
    // }

    if (order === 'asc') {

      if (prevTag < nextTag) {
        return lesser;
      }

      if (prevTag > nextTag) {
        return higher;
      }

    } else {

      if (prevTag > nextTag) {
        return lesser;
      }
      if (prevTag < nextTag) {
        return higher;
      }

    }

    return 0;
  });
};

function isNumber(string: string) {
  return /^-{0,1}\d+$/.test(string);
}
